import { graphql, Link, withPrefix } from 'gatsby';
import React from 'react';
import { Button, Card, Columns, Container, Element, Heading, Hero, Section } from 'react-bulma-components';
import Layout from '../components/Layout';
import "../index.sass";


interface IndexPageTemplateProps {
    title: string;
    description: string;
    workflow: any;
    introduction: any;
}

export const IndexPageTemplate = (props: IndexPageTemplateProps) => {
    const { title, description, introduction, workflow } = props;

    const introductions = introduction.map((intro) => (
        <Columns.Column size="half">
            <Card style={{minHeight: "280px"}}>
                <Card.Content>
                    <Element style={{textAlign: 'center'}}>
                        <Heading>{intro.title}</Heading>
                    </Element>
                    <br />
                    <p>{intro.content}</p>
                    <hr></hr>
                    <Button
                      color="primary"
                      renderAs={Link} to={intro.link}
                      rounded outlined={true}
                      >
                        Read more
                    </Button>
                </Card.Content>
            </Card>
        </Columns.Column>
    ));

    return (
        <React.Fragment>
        <Section size="medium">
            <Hero>
                <Hero.Body>
                    <Container>
                        <Columns>
                            <Columns.Column>
                                <Heading>
                                    <img src={`${withPrefix('/')}img/Model_Broker_logo.svg`} style={{margin: 0, height: '28px'}} />
                                    &nbsp; {title}
                                </Heading>
                                <p style={{marginTop: '70px'}}>{description}</p>
                                <Button.Group style={{marginTop: '70px'}}>
                                    <Button
                                        color={'primary'}
                                        rounded={true}
                                        renderAs={Link} to="/about"
                                    >
                                        Read more  &nbsp;&nbsp;→
                                    </Button>
                                </Button.Group>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='embed-container'>
                                    <iframe width="560" height="315" src="https://youtube.com/embed/SOHwcm8Z6Dc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </Columns.Column>
                        </Columns>
                    </Container>
                </Hero.Body>
            </Hero>
        </Section>

        <Section>
            <Container style={{maxWidth: "1300px"}}>
                <Columns>
                    {introductions}
                </Columns>
            </Container>
        </Section>
        <Section size="small"></Section>
        <Section size="medium" className="third-section">
            <Element className="third-section-bg" />
            <Container style={{ marginBottom: '50px'}} >
                <Columns>
                     <Columns.Column size={6} offset={3}>
                        <Card style={{ textAlign: 'center' }}>
                            <Card.Content>
                                <img src={`${withPrefix('/')}img/lightbulb.svg`}></img>
                                <Heading>Ready to hear more?</Heading>
                                <Button renderAs={Link} to="/contact" size="large" color="primary" rounded >Contact </Button>
                            </Card.Content>
                        </Card>
                    </Columns.Column>
                </Columns>
            </Container>
        </Section>
    </React.Fragment>
)}

interface IndexPageProps {
    data: any;
}

const IndexPage = (props: IndexPageProps) => {
    const { data } = props;
    const { markdownRemark: post } = data
    const indexPageTemplateProps: IndexPageTemplateProps = post.frontmatter;
    return (
        <Layout isIndex={true}>
            <IndexPageTemplate {...indexPageTemplateProps} />
        </Layout>
    )
}

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        workflow {
          title
        }
        introduction {
            title
            content
            link
        }
      }
    }
  }
`
